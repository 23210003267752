package com.kekod.kekoddev.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun HomePage() {
    LaunchedEffect(Unit) {
        document.title = "KekodDev"
    }
    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Image(
            src = "/kekod.png",
            alt = "KekodDev Logo",
            modifier = Modifier.fillMaxSize()
        )

        SpanText(
            text = "Çok yakında 🥳",
            modifier = Modifier
                .fillMaxSize()
                .background(Color.rgb(0, 0, 0).copy(alpha = 160))
                .fontSize(80.px)
                .textAlign(TextAlign.Center)
                .alignContent(AlignContent.Center),
        )
    }
}
